@import "../node_modules/normalize.css/normalize";

html, body {
  min-height: 100%;
}

body {
  font: 17px/1.7em 'Inter', sans-serif;
  background: #1F1F1F;
}

header {
  background: #fff;
  height: 60px;
  text-align: center;
  padding: 10px 0;

  #logo {
    border: none;

    img {
      max-height: 60px;
    }
  }
}

main {
  background: #fff;

  > section {
    padding: 50px 0;

    > .text {
      max-width: 550px;
      padding: 0 15px;
      margin: 0 auto;
    }

    > .images {
      max-width: 550px;
      margin: 0 auto;

      p {
        text-align: center;
      }
    }

    &:not(:first-child) {
      border-top: 1px solid #E9E9E9
    }
  }
}

h1, h2, h3 {
  font-weight: 500;
}

h3 {
  margin-bottom: 0;
}

.subheading {
  font-size: 14px;
  line-height: 1.4em;
}

img, video {
  max-width: 100%;
}

a {
  color: #0024AF;
  text-decoration: none;
  border-bottom: 1px solid #d4dbf6;
  transition: border-bottom-color 0.2s;

  &:hover {
    border-bottom-color: #a9afc4;
  }
}

footer {
  background: #1F1F1F;
  font-size: 12px;
  line-height: 1.5em;
  text-align: center;
  padding: 25px 0;

  &, a {
    color: #929292;
  }

  a {
    text-decoration: none;
    border-bottom: 1px solid #2b2b2b;
    transition: border-bottom-color 0.2s;

    &:hover {
      border-bottom-color: #3d3d3d;
    }
  }

  p {
    margin: 0 0 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &.separator {
      font-size: 8px;
    }
  }
}
