@import "base";

#intro {
  text-align: center;
}

#departments {
  background: #1F1F1F;
  color: #fff;
  text-align: center;

  h2 {
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
    border: none;
    display: inline-block;
    padding: 20px;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.01);
    }
  }

  a.set-designer {
    font: 36px/1em 'Italiana', serif;
  }

  a.developer {
    font: 26px/1em 'Fira Code', monospace;
  }

  a.photographer {
    font: 34px/1em 'Playfair Display', serif;
  }
}

#other-departments {
  text-align: center;

  //ul {
  //  list-style: none;
  //  padding: 0;
  //  margin: 0;
  //}
  //
  //li {
  //  display: inline;
  //}

  a {
    color: #000;
    text-decoration: none;
    border: none;
    //display: inline-block;
    //padding: 3px;
    //margin-right: 10px;
    padding: 1px 5px;
  }

  .separator {
    font-size: 12px;
    top: -2px;
    position: relative;
  }
}
